import "./Header.scss";
import ButtonOutline from "./ButtonOutline";
import logo from "../../assets/images/logo.png";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { ReactComponent as Menu } from "../../assets/icons/menu.svg";
import { Add } from "iconsax-react";

import { ReactComponent as TelegramIcon } from "../../assets/icons/Telegram.svg";
import { ReactComponent as MediumIcon } from "../../assets/icons/Medium.svg";
import { ReactComponent as DexToolsIcon } from "../../assets/icons/DexTools.svg";
import { ReactComponent as XLogo } from "../../assets/icons/XLogo.svg";
import { ReactComponent as GitbookIcon } from "../../assets/icons/Gitbook.svg";

export default function Header() {
	/* sidebar active */
	const [sidebarActive, setSidebarActive] = useState(false);

	const toggleSidebar = () => {
		setSidebarActive(!sidebarActive);
	};

	const closeSidebar = () => {
		setSidebarActive(false);
	};

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const handleScroll = (e) => {
		e.preventDefault(); // Prevent default anchor behavior
		closeSidebar();
		const target = e.target.getAttribute("href").substring(1);
		const targetElement = document.querySelector(target);
		const headerHeight = document.querySelector(".header").offsetHeight;

		const yOffset = -headerHeight; // Negative offset to account for the header
		const yPosition =
			targetElement.getBoundingClientRect().top +
			window.pageYOffset +
			yOffset;

		window.scrollTo({ top: yPosition, behavior: "smooth" });
	};

	return (
		<div className="header">
			<div className="header-content content-wrapper-lg">
				<div className="header-left">
					<button className="sidebar-toggle" onClick={toggleSidebar}>
						<Menu />
					</button>

					<NavLink to="/">
						<img src={logo} alt="Logo" className="header-logo" />
					</NavLink>
				</div>

				<div className="header-right">
					<nav>
						<ul className="header-nav">
							<li>
								<NavLink
									to="/"
									className={({ isActive }) =>
										isActive ? "active" : ""
									}
								>
									Home
								</NavLink>
							</li>
							<li>
								<a href="https://orion-11.gitbook.io/">Docs</a>
							</li>
							<li>
								<a href="/#features" onClick={handleScroll}>
									Features
								</a>
							</li>
							<li
								className={`dropdown-wrapper ${
									isDropdownOpen ? "active" : ""
								}`}
								onMouseEnter={() => setIsDropdownOpen(true)}
								onMouseLeave={() => setIsDropdownOpen(false)}
							>
								<button className="dropdown-btn">
									Community
									<span className="dropdown-icon ms-2">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											viewBox="0 0 24 24"
											fill="none"
										>
											<path
												stroke="#ffffff"
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeMiterlimit="10"
												strokeWidth="1.5"
												d="M19.92 8.95l-6.52 6.52c-.77.77-2.03.77-2.8 0L4.08 8.95"
											></path>
										</svg>
									</span>
								</button>

								<div className="dropdown-content">
									<ul className="dropdown-items">
										<li>
											<a href="https://t.me/OrionBrowse">
												<TelegramIcon className="header-social-icon" />
												Telegram
											</a>
										</li>
										<li>
											<a href="https://x.com/orionerc_?s=21">
												<XLogo className="header-social-icon" />
												X Platform
											</a>
										</li>
										<li>
											<a href="https://orion-11.gitbook.io/">
												<GitbookIcon className="header-social-icon" />
												GitBook
											</a>
										</li>
										<li>
											<a href="https://medium.com/@teamorionerc">
												<MediumIcon className="header-social-icon" />
												Medium
											</a>
										</li>
										<li>
											<a href="#">
												<DexToolsIcon className="header-social-icon" />
												Dex Tools
											</a>
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</nav>

					<ButtonOutline className="launch-app-btn">
						Launch App
					</ButtonOutline>
				</div>
			</div>

			<div
				className={`sidebar-backdrop ${sidebarActive ? "active" : ""}`}
				onClick={closeSidebar}
			></div>
			<div className={`sidebar ${sidebarActive ? "active" : ""}`}>
				<div className="sidebar-content">
					<div className="sidebar-header">
						<button
							className="sidebar-close"
							onClick={closeSidebar}
						>
							<Add
								size="40"
								color="#808080"
								className="add-icon"
							/>
						</button>
					</div>

					<div className="sidebar-body">
						<ul className="sidebar-nav">
							<li>
								<NavLink
									to="/"
									className={({ isActive }) =>
										isActive ? "active" : ""
									}
								>
									Home
								</NavLink>
							</li>
							<li>
								<a href="https://orion-11.gitbook.io/">Docs</a>
							</li>
							<li>
								<a href="/#features" onClick={handleScroll}>
									Features
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}
